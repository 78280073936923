@use '@onfido/castor';

.introVideo {
  position: relative;
  margin: 0 auto;
  font-size: 0;
}

.video {
  max-width: 100%;
  width: 100%;

  &:focus,
  &:focus-visible {
    outline: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.button {
  position: absolute;
  right: castor.space(1);
  bottom: castor.space(1);
  width: 48px;
  height: 48px;
  border-radius: 50% !important;
  padding: 0;
  animation: fadeIn 0.2s forwards;

  svg {
    width: 40px;
    height: 40px;
  }
}
