@import '../../../Theme/constants';

.instructions {
  font-size: var(--onfido-font-size-small);
  text-align: initial;
  margin: 0 0 24 * $unit-small;
  @media (--small-viewport) and (--short-viewport) {
    font-size: var(--onfido-font-size-x-small);
  }
  @media (--small-viewport) {
    margin: 0 0 12 * $unit-small;
  }
}

.instructionsTitle {
  font-weight: 600;
  margin-bottom: 16 * $unit-small;
}

.button {
  margin-left: auto;
  margin-right: auto;
}

.strong {
  font-weight: bold;
}

.icon {
  vertical-align: middle;
  margin-left: 5 * $unit-small;
}

.icon-no-margin {
  vertical-align: middle;
}
