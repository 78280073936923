@import '../../../Theme/constants';

.image {
  height: auto;
  width: 100%;
  font-size: var(--onfido-font-size-small);
  display: flex;
  flex-direction: row;
  flex: 2 1 32 * $unit-x-small;
  min-height: 32 * $unit-x-small;
  max-width: 135.01 * $unit-small;
  width: 100%;
  padding: 10 * $unit-small;
  margin: auto;
  align-items: center;
  // To fix Safari flexbox rendering issue by forcing the div to be rendered in a new composite layer,
  // see https://stackoverflow.com/questions/65524383/flexbox-bug-on-safari -> https://stackoverflow.com/questions/19169089/weird-css-stretching-issue-in-ios7-safari-and-chrome
  transform: translateZ(0);
}

.twoImages {
  max-width: 2 * 136 * $unit-small;
  align-items: center;
  @media (--small-viewport) {
    max-width: 2 * 135 * $unit-small;
  }
}

%graphic {
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  height: 136 * $unit;
  width: 100%;

  @media (--small-viewport) {
    background-size: contain;
    flex-grow: 1; // prevents container height collapsing on iOS devices
  }
}

.iconAllow {
  @extend %graphic;
}
