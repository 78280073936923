@import '../../Theme/constants';

.icon {
  @extend %icon-circle-background;
  height: 144 * $unit;
  width: 144 * $unit;
  background-size: contain;
  background-image: url('./assets/connection-large.svg');
  margin-top: 24 * $unit;
  margin-bottom: 10 * $unit;
  margin-left: auto;
  margin-right: auto;
}

/* placeholder to namespace this with CSS modules */
.help {
  width: 100%;
}

.cancel {
  cursor: pointer;
}

.header {
  position: relative;
  z-index: 1;
  margin-top: 0;
  top: 16 * $unit;
}

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-around;
  align-items: flex-start;
}
