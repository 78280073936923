@import '../Theme/constants';

.inner {
  /*
   * We set the `font-size` to 16px here. This will fix the rest of the SDK
   * (which uses relative units) to this absolute unit.

   * To opt-in to relative font-sizes, you can override this style to a relative
   * font-size e.g. `font-size: 1em`
   */
  font-size: 16px;

  /* Relative positioning so overflow affects absolute children */
  position: relative;
  overflow: auto;
  margin: auto;

  width: 94vw;
  max-width: $sdk-container-max-width;
  height: 600 * $unit;
  text-align: center;
  max-height: calc(100% + #{4 * $unit});
  background-color: var(--osdk-color-background-surface-modal);
  border-color: var(--osdk-color-border-surface-modal);
  border-width: var(--osdk-border-width-surface-modal);
  border-style: var(--osdk-border-style-surface-modal);
  border-radius: var(--osdk-border-radius-surface-modal);

  font-family: var(--osdk-font-family-body) !important;
  color: var(--osdk-color-content-body);
  font-weight: var(--osdk-font-weight-body);
  line-height: 1.5;

  @media (--small-viewport) {
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 0;
  }

  &,
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }
}

.closeButton {
  background-color: transparent;
  background-image: url('./assets/cross.svg');
  background-size: contain;
  border-radius: 16 * $unit;
  border: 0;
  cursor: pointer;
  font-family: var(--osdk-font-family-body);
  height: 32 * $unit;
  position: absolute;
  right: 15 * $unit;
  text-decoration: none;
  top: 15 * $unit;
  width: 32 * $unit;
  z-index: 4;

  @media (--small-viewport) {
    top: 10 * $unit;
    right: 8 * $unit;
  }
}

[dir='rtl'] .closeButton {
  left: 15 * $unit;
  right: auto;

  @media (--small-viewport) {
    left: 8 * $unit;
    right: auto;
  }
}

.closeButton {
  &:hover {
    .closeButtonLabel {
      display: block;

      @media (--small-viewport) {
        /* Safari Mobile tries to show "hover" text on click, so we make sure
         * that mobile devices don't have any "hover text" */
        display: none;
      }
    }
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--osdk-color-background-button-icon-hover);
    }
  }

  &:active {
    background-color: var(--osdk-color-background-button-icon-active);
  }
}

.closeButtonLabel {
  display: none;
  position: absolute;
  right: 100%;
  top: 50%;
  transform: translateY(-50%);
  font-weight: 500;
  font-size: var(--onfido-font-size-small);
  margin-inline-end: 7 * $unit-small;
}

[dir='rtl'] .closeButtonLabel {
  left: 100%;
  right: auto;
}

.closeButtonFullScreen {
  background-image: url('./assets/cross-white.svg');

  &:hover {
    background-image: url('./assets/cross.svg');
    background-color: $color-fullscreen-icon-button-hover;
  }

  &:active {
    background-color: $color-fullscreen-icon-button-active;
  }

  .closeButtonLabel {
    color: $color-white;
  }
}
