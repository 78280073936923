@import '../Theme/constants';

.wrapper {
  margin-top: 116 * $unit;
}

.icon {
  height: 64 * $unit;
  margin-bottom: 8 * $unit;
}
