@import '../Theme/constants';

.qrCodeHelp {
  min-height: unset;
}

.qrCodeHelpButton {
  cursor: pointer;
}

.qrCodeHelpIcon {
  width: 20 * $unit;
  height: 16 * $unit;
  display: inline-block;
  vertical-align: text-bottom;
}

[dir='rtl'] .qrCodeHelpIcon {
  @extend %icon-rtl;
}

.qrCodeHelpList {
  font-size: var(--onfido-font-size-small);
  text-align: initial;
  margin-bottom: 16 * $unit;
}
