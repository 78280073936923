@import '../../Theme/constants';

.content {
  font-size: var(--onfido-font-size-base);
  position: relative;
  margin-bottom: 16 * $unit;
  flex: 1;
  flex-direction: column;
  display: flex;
  margin: 0 48 * $unit auto;

  @media (--small-viewport) {
    margin: 0 0 auto;
  }
}

.subTitle {
  font-size: var(--onfido-font-size-large);
  font-weight: 400;
}

.warningSmallIcon {
  display: inline-block;
  width: 16 * $unit-small;
  height: 16 * $unit-small;
  vertical-align: middle;
  line-height: 1;
  margin-inline-end: 5 * $unit-small;
  margin-top: -2 * $unit-small;
}

.bolder {
  font-weight: 600;
}

.makeSure {
  color: var(--osdk-color-content-body);
  font-weight: 600;
  margin-bottom: 8 * $unit;
  text-align: initial;
}

.docImageContainer {
  position: relative;
  flex: 1;
  width: 340 * $unit;
  margin: 0 auto;
  @media (--small-viewport) {
    width: 100%;
  }
}

.docImage {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.list {
  text-align: initial;
  margin: 0;
  padding: 0;
  padding-inline-start: 16 * $unit;
}

.requirement {
  padding-bottom: 16 * $unit;

  &::marker {
    color: var(--osdk-color-background-alert-info);
  }
}

.label {
  fill: var(--osdk-color-content-body);
  font-size: var(--onfido-font-size-small);
}
