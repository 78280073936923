@import '../Theme/constants';

/* Face */

@mixin position-hole($scale) {
  $overlay-width: 740 * $unit;
  $overlay-height: 1800 * $unit;
  $hole-width: 264 * $unit;
  $hole-height: 328 * $unit;
  $offset-y: 105 * $unit;

  background-size: ($overlay-width * $scale) ($overlay-height * $scale);
  background-position: 50%
    calc(#{($hole-height - $overlay-height) * $scale * 0.5 + $offset-y});
}

.face {
  @include position-hole(1);
  background-image: url('./assets/face-with-hole.svg');
  background-repeat: no-repeat;
  height: 100%;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  @media (--smaller-viewport) {
    @include position-hole(0.85);
  }

  .isWithoutHole & {
    background-image: url('./assets/face-without-hole.svg');
  }
}

.ariaLabel {
  opacity: 0;
}
