@use '@onfido/castor';

.intro {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.introVideo {
  $size: 140px;
  width: $size;
  height: $size;
  min-width: $size;
  min-height: $size;
}

.introButton {
  bottom: 0;
  right: 0;
}

.wrapperBody {
  justify-content: space-between !important;
}

.list {
  padding: 0;
  margin: 0 0 castor.space(4);
  width: 100%;
  row-gap: castor.space(1);
  display: flex;
  flex-direction: column;
  text-align: left;

  .listItem {
    list-style: none;

    .text {
      @include castor.font('300-regular');
      color: var(--osdk-color-content-body);
      display: flex;
      align-items: center;

      &::before {
        $size: 6px;
        display: inline-block;
        content: '';
        width: $size;
        height: $size;
        min-width: $size;
        min-height: $size;
        margin-inline-end: castor.space(2);
        border-radius: 50%;
        background: castor.color('primary-500');
      }
    }
  }
}
