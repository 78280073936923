@import '../Theme/constants';

.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
}

.list {
  list-style: none;
  padding: 0;
}

.stage {
  display: flex;
  align-items: center;

  /* to position line connecting all three icons */
  padding-bottom: 32 * $unit;
  position: relative;

  /* line connecting all three icons */
  &::before {
    content: '';
    display: block;
    background-color: $color-icons-connector-line;
    width: 2 * $unit;
    height: 100%;
    position: absolute;
    top: 0;
    margin-inline-start: 23 * $unit;
  }

  &:last-child::before {
    display: none;
  }
}

.itemIcon {
  @extend %icon-circle-background;
  background-size: contain;
  flex-shrink: 0;
  float: left;
  height: 48 * $unit;
  width: 48 * $unit;
  position: relative;
  z-index: 1;
}

[dir='rtl'] .itemIcon {
  @extend %icon-rtl;
}

.itemMessage {
  text-align: initial;
  margin-inline-start: 15 * $unit;
  flex-grow: 1;
  flex-basis: 100%;
  color: var(--osdk-color-content-body);
}
