@import '../Theme/constants';

.expanded {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  background-color: $color-white;
}

.imageContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image {
  min-height: 100%;
  min-width: 100%;
  object-fit: contain;
  object-position: center;
  display: block;
  touch-action: none;
}

.container {
  /*
  HACK this container is only necessary in order to increase
  the specifycity when compared to the Button variant class used
  */
  .button {
    position: absolute;
    display: flex;
    align-items: center;
    left: 50%;
    bottom: 0%;
    transform: translate(-50%, -50%);
    padding: 9 * $unit 15.4 * $unit;
    border: 0;

    .button-text {
      font-size: var(--onfido-font-size-x-small);
      position: relative;
    }
  }
}

.enlargeIcon {
  display: inline-block;
  width: 12 * $unit;
  height: 12 * $unit;
  background-image: url('./assets/enlarge.svg');
  background-repeat: no-repeat;
  background-size: contain;
  margin-inline-end: 10 * $unit;
}

.collapseIcon {
  display: inline-block;
  width: 14 * $unit;
  height: 14 * $unit;
  background-image: url('./assets/collapse.svg');
  background-repeat: no-repeat;
  background-size: contain;
  margin-inline-end: 10 * $unit;
}

.expanded {
  .button {
    top: auto;
    bottom: 16 * $unit;
    font-size: inherit;
  }
}

.button-overlay {
  background-color: $color-small-button !important;
  border-radius: 4 * $unit;
  color: $color-white;
  font: inherit;
  font-weight: 600;
  height: auto !important;
  line-height: 1.4;
  padding: 11.2 * $unit 19.6 * $unit;

  &:hover {
    background-color: $color-small-button-hover;
  }

  &:active {
    background-color: $color-small-button-active;
  }
}
