@use '@onfido/castor';

.disclaimer {
  display: flex;
  align-items: center;
  padding: castor.space(1.5) castor.space(2);
  border-radius: castor.border-radius('large');
  background: castor.color('primary-100');
  text-align: initial;
  margin-bottom: castor.space(2);

  svg {
    min-width: 24px;
    margin-inline-end: castor.space(1.5);
    fill: castor.color('primary-500');
  }

  .text {
    @include castor.font('200-regular');
    color: castor.color('neutral-800');
    line-height: 20px;
  }
}
