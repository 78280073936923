@import '../Theme/constants';

.actions {
  padding: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-inline-end: -16 * $unit;

  @media (--small-viewport) {
    margin-inline-end: -8 * $unit;
  }

  &.vertical {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.singleAction {
  display: block;
  margin-bottom: 8 * $unit;
}

.retakeAction {
  margin-inline-end: 16 * $unit;
  @media (--small-viewport) {
    margin-inline-end: 8 * $unit;
  }

  &.vertical {
    margin-inline-end: 0;
    margin-top: 8 * $unit;
    width: $lg-btn-width-lg-screen;
  }
}
