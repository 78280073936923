@import '../../Theme/constants';

.content {
  text-align: initial;
  margin: 0 48 * $unit auto;

  @media (--small-viewport) {
    margin: 0 0 auto;
  }
}

.list {
  padding-inline-start: 16 * $unit;
}

.requirements {
  font-weight: 600;
  color: var(--osdk-color-content-body);
  margin-bottom: 16 * $unit;
}

.requirement {
  display: list-item;
  padding-bottom: 16 * $unit;

  &::marker {
    color: var(--osdk-color-background-alert-info);
  }
}

.bolder {
  display: inline-block;
  font-weight: 600;
}
