@import '../Theme/constants';

.container {
  flex: 1 1 auto;
  padding-top: 16 * $unit;
}

%_text {
  font-size: var(--onfido-font-size-small);
}

.label {
  @extend %_text;
}

.errorContainer {
  margin-top: 8 * $unit;
  display: flex;
}

.fallbackText {
  @extend %_text;
  font-weight: bold;
}

.descriptionText {
  @extend %_text;
}

.fallbackLink {
  display: inline-block;
  font-size: inherit;
}

.icon {
  display: inline-block;
  height: 13 * $unit;
  width: 13 * $unit;
  margin-inline-end: 8 * $unit;
}
