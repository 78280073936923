@import '../../../Theme/constants';

.listRow {
  display: flex;
  margin-bottom: 10 * $unit-small;
}

.numberedBullet {
  width: 24 * $unit-small;
  height: 24 * $unit-small;

  min-width: 24 * $unit-small;
  min-height: 24 * $unit-small;

  border: 2 * $unit-small solid $color-border;
  border-radius: 12 * $unit-small;
  text-align: center;

  margin-right: 10 * $unit-small;
}

.list {
  padding: 0;
}
