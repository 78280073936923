@use '@onfido/castor';

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.title {
  font-size: var(--osdk-font-size-title);
  color: var(--osdk-color-content-title);
  font-family: var(--osdk-font-family-title);
  font-weight: var(--osdk-font-weight-title);
  margin: castor.space(4) 0;
  line-height: 1.34;

  &:not(:last-child) {
    margin-bottom: castor.space(1);
  }
}

.subtitle {
  @include castor.font('300-regular');
  color: castor.color('neutral-600');
  margin: 0 0 castor.space(4);
}
