@import '../Theme/constants';

.introVideo {
  width: 300px;
  max-width: 100%;
}

.challengeDescription {
  line-height: 2.25;
}

%_challenge-text {
  font-weight: 600;
  font-size: 20 * $unit;
}

.challengeTitle {
  @extend %_challenge-text;
  line-height: 1.2;
}

.challengeSubTitle {
  @extend %_challenge-text;
  line-height: 2;
}

.introCopy {
  margin-bottom: auto;
  padding-bottom: 12 * $unit;
}

.introBullets {
  padding-inline-start: 40 * $unit;

  @media (--small-viewport) {
    padding-inline-start: 0;
  }
}

.introBullet {
  display: flex;
  text-align: initial;
  margin-bottom: 24 * $unit;
  list-style: none;
  position: relative;
}

.bolder {
  font-weight: 600;
}

.introIcon {
  margin-inline-end: 20 * $unit;
  background-repeat: no-repeat;
  background-size: contain;
  width: 26 * $unit;
  height: 26 * $unit;
}

.speakOutLoudIcon {
  background-image: url('./assets/mic.svg');
  background-size: contain;
}

.twoActionsIcon {
  background-image: url('./assets/camera.svg');
  background-size: contain;
}

.movement {
  background-image: url('./assets/move-arrow.svg');
  background-size: contain;
  display: inline-block;
  width: 65 * $unit;
  height: 21 * $unit;
  margin-top: 16 * $unit;
}

.movement-turnLeft {
  transform: rotate(180deg);
}

.recite {
  display: block;
  font-size: 54 * $unit;
  font-weight: 600;
  line-height: 1.19;
  margin-top: 2.37 * $unit;
}

.actions {
  position: absolute;
  z-index: 1000;
  bottom: 48 * $unit;
  left: 16 * $unit;
  right: 16 * $unit;

  &.disabled {
    z-index: 0;
  }
}

.btn:extend(.btn, .btn-centered, .btn-primary) {
  padding: 0;
  margin-left: auto;
  margin-right: auto;
}

.captureActionsHint {
  color: $color-white;
  font-size: var(--onfido-font-size-small);
  margin-bottom: 10 * $unit-small;

  &.recordAction {
    @media (--smaller-viewport) {
      display: none;
    }
  }
}

.caption {
  color: $color-white;
  text-align: center;
  position: absolute;
  z-index: 1;
  top: 64 * $unit;
  left: 0;
  right: 0;
}

@mixin _full-screen-rounded-icon() {
  cursor: pointer;
  width: 60 * $unit;
  height: 60 * $unit;
  font-size: inherit;
  border: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: transparent;
  box-shadow: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &:hover:not(:disabled) {
    filter: brightness(200%);
  }

  &:active {
    filter: brightness(80%);
  }
}

@mixin _full-screen-start-recording-icon() {
  @include _full-screen-rounded-icon();
  background-image: url('./assets/start.svg');
}

@mixin _full-screen-stop-recording-icon() {
  @include _full-screen-rounded-icon();
  background-image: url('./assets/stop.svg');
}

.startRecording {
  @include _full-screen-start-recording-icon();
}

.stopRecording {
  @include _full-screen-stop-recording-icon();
}
