@import '../Theme/constants';

.selectionContainer {
  margin-top: 24 * $unit;
  text-align: initial;
}

.disabledInput {
  height: 38 * $unit;
}

.singleCountryInputWrapper {
  // mirrors the same class of the dropdown
  margin-top: 8 * $unit;

  @media (--small-viewport) {
    margin-top: 0;
  }
}
